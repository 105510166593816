import { RichText } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';

export const StyledContent = styled(RichText)`
  --richtext-spacing: 16px;
  margin-bottom: 40px;

  h2 {
    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  ${({ $minimal }) =>
    $minimal &&
    css`
      p {
        margin: 0;
      }

      i:only-child,
      em:only-child,
      b:only-child,
      strong:only-child,
      u:only-child {
        display: block;
        margin-top: var(--richtext-spacing);
      }
    `}
`;
