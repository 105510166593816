import { Button, LinkButton } from '@/components/core/Button';
import { Paragraph, Title } from '@/components/core';
import PostingCategories from '../PostingCategories';

import { Header } from './styles';

const HeaderJoinUs = ({ title, description, backLink, posting, button }) => {
  const backLinkProps = {
    label: backLink?.label || 'Back to Careers',
    href: backLink?.href || '/company/careers#join-us-section',
  };

  return (
    <Header forwardedAs="header" innerSpacer>
      <LinkButton $primary href={backLinkProps.href} $isLeft>
        {backLinkProps.label}
      </LinkButton>
      <Title as="h1" className="title">
        {title}
      </Title>
      {posting && <PostingCategories posting={posting} />}
      {description && <Paragraph as="p">{description}</Paragraph>}
      {button && <Button href={button.href}>{button.label}</Button>}
    </Header>
  );
};

export default HeaderJoinUs;
