import styled from 'styled-components';

import { Container } from '@/components/core/flexbox';
import { Heading, Paragraph } from '@swordhealth/ui-corporate';

export const StyledContainer = styled(Container)`
  max-width: 712px;

  .back-button {
    margin-bottom: ${(props) => props.theme.spacings.sm};

    @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
      margin-bottom: ${(props) => props.theme.spacings.md};
    }
  }
`;

export const StyledFieldset = styled.fieldset`
  border: none;
  display: grid;
  gap: ${(props) => props.theme.spacings.xl};
  padding: 0;
  margin: ${(props) => `0 0 ${props.theme.spacings.xl}`};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledLegend = styled(Heading).attrs({ forwardedAs: 'legend', size: 'lg' })`
  display: block;
  padding-bottom: ${(props) => props.theme.spacings.md};
  width: 100%;
`;

export const StyledLegendInfo = styled(Paragraph).attrs({ forwardedAs: 'span', size: 'md' })`
  display: block;
  padding-bottom: ${(props) => props.theme.spacings.md};
  padding-top: ${(props) => props.theme.spacings.md};
`;
