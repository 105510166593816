import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: 16px;

  .text {
    h5 {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 16px;
    }
    p {
      margin: 0;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      h5 {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 24px;
    padding: 24px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;

  .customButton {
    background-color: var(--ui-colors-grey-dark);
    border-color: var(--ui-colors-grey-dark);
    color: var(--ui-colors-white);
    &:hover {
      background-color: var(--ui-colors-grey-default);
      border-color: var(--ui-colors-grey-default);
      color: var(--ui-colors-white);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
  }
`;
