import fetcher from './fetcher';

const leverClient = async (url, options = {}) =>
  await fetcher(`https://api.lever.co/v1${url}`, {
    ...options,
    headers: {
      ...(options?.headers || {}),
      Authorization: `Basic ${Buffer.from(process.env.LEVER_API_KEY + ':' + '').toString('base64')}`,
    },
  });

export default leverClient;
