import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 16px;
  padding: 40px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
  }
  .text {
    text-align: center;

    strong {
      text-transform: capitalize;
      font-weight: 500;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px;
  }
`;

export const Figure = styled.figure`
  img {
    object-fit: contain;
    display: block;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    img {
      width: 64px;
      height: 64px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }
`;
