import { memo } from 'react';

import { Container } from '@/components/core/flexbox';

import { StyledSection, StyledGrid, StyledFooter } from './styles';
import { Intro } from '@/components/shared';

const GridCards = ({ id, upperLabel, title, children, footer }) => (
  <StyledSection id={id} gradient="linear-gradient(180deg, #EEF4FD 0%, #FFF 100%)" spacerTop>
    <Intro
      title={title}
      titleSize="3xl"
      upperLabel={upperLabel}
      upperLabelVariant="badge"
      innerSpacer
    />
    <Container as="section">
      <StyledGrid>{children}</StyledGrid>
      {footer && <StyledFooter data-testid="grid-cards-footer">{footer}</StyledFooter>}
    </Container>
  </StyledSection>
);

export default memo(GridCards);
